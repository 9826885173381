<template>
  <div>
    <!-- 基本信息 -->
    <div class="block">
      <h3 class="flex flex-align-center flex-justify-between">
        基本信息设置
        <!-- <a style="font-weight:400;" v-show="!editInfo" @click="edit()">修改</a> -->
        <el-button v-show="!editInfo" @click="edit()" type="text">修改</el-button>
      </h3>
      <div class="block-content">
        <div class="item flex flex-align-center" :class="{ required: editInfo }">
          <label>姓名</label>
          <div v-if="!editInfo && user.name">{{ user.name }}</div>
          <div v-else>
            <el-input v-model="user.name" />
          </div>
        </div>
        <div class="item flex flex-align-center">
          <label>性别</label>
          <div v-if="!editInfo">
            <span v-if="user.gender == 1">男</span>
            <span v-if="user.gender == 2">女</span>
          </div>
          <div v-else>
            <el-radio-group v-model="user.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div v-if="!editInfo" class="item flex flex-align-center">
          <label>实名状态</label>
          <div>
            <span v-if="user.cert_stat==0" style="margin-right:10px">未认证</span>
            <span v-if="user.cert_stat==1" style="margin-right:10px">认证中</span>
            <span v-if="user.cert_stat==2" style="margin-right:10px">已认证</span>
            <span v-if="user.cert_stat==3" style="margin-right:10px">认证失败</span>
            <el-button v-if="user.cert_stat==0" type="text" @click="goApprove">立即认证</el-button>
            <el-button v-else type="text" @click="approveLink">重新认证</el-button>
          </div>
        </div>
        <div class="item flex">
          <label>头像</label>
          <div v-if="!editInfo">
            <img v-if="user && user.avatar" :src="imgUrl + user.avatar" />
            <img v-else src="../../assets/images/user.png" />
          </div>
          <div v-else>
            <!-- <el-upload
              class="avatar-uploader"
              action="xx"
              :show-file-list="false"
              accept="image/jpeg, image/gif, image/png"
              :http-request="uploadAvatar"
              :before-upload="beforeUpload"
            >
              <img v-if="user.avatar" :src="imgUrl + user.avatar" class="baseinfo-avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload> -->
            <UploadImg v-model="user.avatar" prefix='user/head'></UploadImg>
          </div>
        </div>
        <div class="item flex flex-align-center">
          <label>联系邮箱</label>
          <div v-if="!editInfo">{{ user.mail }}</div>
          <div v-else>
            <el-input v-model="user.mail" />
          </div>
        </div>
        <div class="item flex flex-align-center">
          <label>微信</label>
          <div v-if="!editInfo">{{ user.wechat }}</div>
          <div v-else>
            <el-input v-model="user.wechat" />
          </div>
        </div>
        <div class="item flex flex-align-center">
          <label>QQ</label>
          <div v-if="!editInfo">{{ user.qq }}</div>
          <div v-else>
            <el-input v-model="user.qq" />
          </div>
        </div>
        <div class="item item-des flex">
          <label>个人介绍</label>
          <div v-if="!editInfo">{{ user.intro }}</div>
          <div v-else style="position:relative;">
            <el-input type="textarea" v-model="user.intro" maxlength="120" rows="5" />
            <div class="font14-grey1" style="position:absolute;right:10px;bottom:6px;">{{(user.intro || '').length}}/120</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editInfo" class="block btn-block">
      <el-button @click="cancelEdit()">取消</el-button>
      <el-button type="primary" @click="saveEdit()">确定</el-button>
    </div>
    <!-- 安全设置 -->
    <div class="block block-safe">
      <h3 class="flex flex-align-center">安全设置</h3>
      <div class="block-content">
        <div class="item flex flex-align-center">
          <label>绑定手机</label>
          <diV>
            <span>{{ user.mobile }}</span>
            <a @click="showDialog(1)">更换</a>
          </diV>
        </div>
        <el-form v-if="editSafeType === 1" :model="ruleForm" :rules="rules" ref="ruleForm" style="max-width:370px;" label-width="90px">
          <!-- <div class="item flex flex-align-center">
            <label>新手机号</label>
            <div>
              <el-input v-model="newPhone" />
            </div>
          </div> -->
          <el-form-item style="margin-top:20px;" prop="newPhone" label="新手机号">
              <el-input v-model="ruleForm.newPhone" :maxlength="11"></el-input>
          </el-form-item>
          <!-- <div class="item flex flex-align-center">
            <label>验证码</label>
            <div class="flex">
              <el-input v-model="code" />
              <div v-if="invsec<=0" @click="getMobileCode()" style="margin-left: -92px;" class="btn-send">获取验证码</div>
            <div v-if="invsec>0">{{invsec}}秒后重试</div>
            </div>
          </div> -->
          <el-form-item prop="code" label="验证码">
              <el-input placeholder="请输入短信验证码" v-model="ruleForm.code">
                  <template slot="append">
                      <div v-if="invsec<=0" @click="getMobileCode" style="width:90px;cursor: pointer;">获取验证码</div>
                      <div v-if="invsec>0">{{invsec}}秒后重试</div>
                  </template>
              </el-input>
          </el-form-item>
          <div class="item flex flex-align-center">
            <div style="margin-left: 80px;">
              <el-button size="small" @click="editSafeType = 0">取消</el-button>
              <el-button @click="submitForm('ruleForm')" size="small" type="primary">重新绑定</el-button>
            </div>
          </div>
        </el-form>
        <div class="item flex flex-align-center">
          <label>修改密码</label>
          <diV>
            <span>重新设置新密码</span>
            <a @click="showDialog(2)">更换</a>
          </diV>
        </div>
        <el-form v-if="editSafeType === 2" :model="ruleForm1" :rules="rules" ref="ruleForm1" style="max-width:420px;" label-width="130px">
          <el-form-item style="margin:20px 0;" prop="mobile" label="手机号">
              <div>{{ruleForm1.mobile}}</div>
          </el-form-item>
          <el-form-item style="margin-bottom:20px;" prop="captcha" label="图形验证码">
              <el-input v-model="ruleForm1.captcha" placeholder="请输入图形验证码" class="reg-create-captcha-ipt">
                  <div slot="append" v-bind:style="{background:captchaBg}" class="captcha-img"
                      @click="refreshCaptcha">
                  </div>
              </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:20px;" prop="code" label="短信验证码">
              <el-input placeholder="请输入短信验证码" v-model="ruleForm1.code">
                  <template slot="append">
                      <div v-if="invsec1<=0" @click="getMobileCode1" style="width:90px;cursor: pointer;">获取验证码</div>
                      <div v-if="invsec1>0" style="width:90px;">{{invsec1}}秒后重试</div>
                  </template>
              </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:20px;" prop="new_passwd" label="请输入新密码">
              <div>
                  <el-input placeholder="请输入密码 (8～20位,区分大小写)" 
                    v-model="ruleForm1.new_passwd" :maxlength="20" 
                    type="text" onfocus="this.type='password'">
                  </el-input>
              </div>
              <div v-if="ruleForm1.new_passwd && ruleForm1.new_passwd.length>7" class="plan" style="margin-top:14px;">
                  <div class='plan-progress flex'>
                      <div :style="planNum && planNum>=1 ? 'background:red;': ''" class="flex-1"></div>
                      <div :style="planNum && planNum>=2 ? 'background:orange;': ''" class="flex-1"></div>
                      <div :style="planNum && planNum==4 ? 'background:#00D1B2;': ''" class="flex-1"></div>
                  </div>
                  <div class="plan-text flex flex-justify-around">
                      <span style="color:red;">弱</span>
                      <span style="color:orange;">中</span>
                      <span style="color:#00D1B2;">强</span>
                  </div>
              </div>
          </el-form-item>
          <el-form-item style="margin-bottom:25px;" prop="new_cpasswd" label="再次输入新密码">
              <el-input placeholder="请再次输入新密码" v-model="ruleForm1.new_cpasswd" 
                type="text" onfocus="this.type='password'">
              </el-input>
          </el-form-item>

          <!-- <el-form-item style="margin-top:20px;" prop="oldpassword" label="旧密码">
            <el-input v-model="ruleForm1.oldpassword" style="max-width:240px;" type="text" onfocus="this.type='password'"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="新密码">
            <el-input v-model="ruleForm1.password" style="max-width:240px;" type="text" onfocus="this.type='password'"></el-input>
          </el-form-item> -->
        </el-form>
        <div class="item flex flex-align-center">
          <div style="margin-left: 80px;">
            <el-button size="small" @click="editSafeType = 0">取消</el-button>
            <el-button @click="submitForm1('ruleForm1')" size="small" type="primary">确认修改</el-button>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 30px;">
      <el-button size="small" type="primary" @click="back()">返回</el-button>
    </div>
    <!-- 身份认证弹窗 -->
    <el-dialog width="500px" :title="popTitle" :visible.sync="popVisible" center>
      <div v-if="popTitle == '个人认证'">
          <Sign :popTitle="popTitle" @data="getPerson" @hide="hide" @signState="signState"></Sign>
      </div>
      <div v-if="popTitle == '身份认证'">
        <div class="dialog-content flex flex-v flex-align-center">
          <div class="flex flex-align-center">
            <label>当前手机</label>
            <div>+86 {{ user.phone }}</div>
          </div>
          <div class="flex">
            <label>验证码</label>
            <div class="flex flex-align-center">
              <el-input style="width:240px;" v-model="dialogData.code"></el-input>
              <div v-if="invsec<=0" @click="getMobileCode()" class="btn-send">获取验证码</div>
              <div v-if="invsec>0">{{invsec}}秒后重试</div>
              <div class="errtip">短信验证码错误</div>
              <diV class="warm">为了保护你的帐号安全，请验证身份，验证成功后进行下一步操作</diV>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="popVisible = false">取 消</el-button>
          <el-button type="primary" @click="next()">下一步</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
import { userVerify, modConfig,modMp,getLogedUserInfo, findPasswordReset } from "@/service/account";
import { uploadFile,delFile,sendLoginSmsCode,sendGetSmsCode,getCaptCha } from "@/service/common";
import UploadImg from '@/components/upload/UploadImg.vue';
import Sign from '@/components/sign/sign.vue';
export default {
  components:{
      UploadImg,Sign
  },
  data() {
    var checkPhone = (rule, value, callback) => {
        if (!value) {
        return callback(new Error('请输入手机号'));
        } else {
            const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
            if (reg.test(value)) {
                callback();
            } else {
                return callback(new Error('请输入正确的手机号'));
            }
        }
    };
    let checkPass = (rule, value, callback) => {
        if (!value) {
            return callback(new Error('请输入密码'));
        } else {
            const reg = /^[\u4e00-\u9fa5]+$/; // 汉字校验
            if (!reg.test(value)) {
                if (this.ruleForm1.new_passwd) {
                    //正则表达式验证符合要求的
                    if(value.length < 8 || value.length > 20) {
                        return callback(new Error('请输入密码 (8～20位, 区分大小写)'));
                    } else {
                        callback();
                    }
                } else {
                    return callback(new Error('请与上面填写密码保持一致'));
                }
            } else {
                return callback(new Error('密码不允许是汉字'));
            }
        }
    };
    let checkCpass = (rule, value, callback) => {
        if (!value) {
            return callback(new Error('请再次输入密码'));
        } else {
            const reg = /^[\u4e00-\u9fa5]+$/; // 汉字校验
            if (!reg.test(value)) {
                if (this.ruleForm1.new_cpasswd == this.ruleForm1.new_passwd) {
                    callback();
                } else {
                    return callback(new Error('与上面密码不匹配'));
                }
            } else {
                return callback(new Error('密码不允许是汉字'));
            }
        }
    };
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: null,
      dialogData: {
        phone: "",
        code: ""
      },
      rules: {
          newPhone: [{required: true,validator: checkPhone, trigger: 'blur'}],
          code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
          // mobile: [{required: true, validator: checkPhone1, trigger: 'blur'}],
          captcha: [{ required: true, message: '请输入图形验证码', trigger: 'blur' }],
          // code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
          new_passwd: [{ required: true, validator: checkPass, trigger: 'blur' }],
          new_cpasswd: [{ required: true, validator: checkCpass, trigger: 'blur' }],
      },
      editInfo: false,
      editSafeType: 0,
      safeFlag: 0,
      prevUser: {},
      user: {},
      ruleForm: { // 修改手机号
          newPhone:"",
          code:""
      },
      invsec: 0,
      timer: null,
      ruleForm1: { // 修改密码
          mobile: '',
          code:'',
          new_passwd:'',
          captcha: '',
          captcha_token: '',
      },
      captchaBg: null,
      captchaToken: null,
      invsec1: 0,
      timer1: null,
      planNum:0,
      saveImg:null,
    };
  },
  watch:{
      'ruleForm1.new_passwd':{
          handler(newValue, oldValue) {
              console.log(newValue);
              if (newValue) {
                  this.checkPlan(newValue);
              }
          },
          deep: true
      }
  },
  async created() {
    this.getPerson();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getPerson() {
      this.user = JSON.parse(JSON.stringify(this.$store.state.loginUser.user));
      let getLogedUser = await getLogedUserInfo();
      Object.assign(this.user,getLogedUser);
      this.saveImg = this.user.avatar;
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
    hide() {
        this.popVisible = false;
        this.$message.success('状态已更新');
    },
    goApprove() { // 个人认证
      userVerify({}).then(rst => {
        if (rst.verify_url) {
          window.open(rst.verify_url);
          this.signState("个人认证");
        }
      }).catch(err => {
        this.$message.error(err.message);
      });
    },
    approveLink() { // 重新认证
      if (this.user.fdd_verify_url) {
        window.open(this.user.fdd_verify_url);
        this.signState("个人认证");
      }
    },
    showDialog(flag) {
      this.safeFlag = flag;
      this.editSafeType = this.safeFlag;
      if (flag == 2) {
        this.ruleForm1.mobile = this.user.mobile;
        this.refreshCaptcha();
      }
      // this.popTitle = "身份认证";
      // this.popVisible = true;
    },
    edit() {
      this.prevUser = { ...this.user };
      console.log(this.prevUser);
      this.editInfo = true;
    },
    cancelEdit() {
      this.editInfo = false;
      this.user = { ...this.prevUser };
    },
    saveEdit() {
      modConfig(this.user)
        .then(() => {
          this.editInfo = false;
          this.$message.success("保存成功");
          // delFile(this.saveImg).then(() => {});
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    getMobileCode() {
        sendLoginSmsCode(this.ruleForm.newPhone).then(() => {
            this.invsec = 60;
            this.timer = setInterval(() => {
                if (this.invsec == 0) {
                    clearInterval(this.timer);
                    return;
                }
                this.invsec--;
            }, 1000);
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        })
    },
    // 修改绑定手机号
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                modMp(this.ruleForm.newPhone,this.ruleForm.code).then(()=>{
                  this.getPerson();
                  this.editSafeType = this.safeFlag = false;
                  this.$message.success("绑定成功");
                }).catch(err=>{
                    this.$message.error(err.message);
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    },
    checkPlan(value) {
        // console.log(value);
        //正则表达式验证符合要求的
        if(value.length < 8 || value.length > 20) return this.planNum;
        if((/\d/.test(value)) || (/[a-z]/.test(value)) || (/[A-Z]/.test(value)) || (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=1; //数字
        if((/\d/.test(value)) && (/[a-z]/.test(value)) || (/\d/.test(value)) && (/[A-Z]/.test(value)) || (/\d/.test(value)) && (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=2; //小写
        if(this.planNum==2 && (/[A-Z]/.test(value)) || this.planNum==2 && (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=3; //大写  
        if(this.planNum==3 && (/^.*[^a-zA-Z0-9]+.*/.test(value)) || this.planNum==3 && (/[A-Z]/.test(value))) this.planNum=4; //特殊字符
        console.log(this.planNum);
        //逻辑处理
        switch(this.planNum) {
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            case 4:
                return value.length < 10 ? 3 : 4;
        }
        console.log(this.planNum);
    },
    refreshCaptcha() {
        getCaptCha().then(rsp => {
            this.captchaBg = `url('data:image/svg+xml,${encodeURIComponent(rsp.svg)}') no-repeat center`;
            this.captchaToken = rsp.token;
        }).catch(err => {
            this.$message.error(err.message);
        })
    },
    getMobileCode1() {
        sendGetSmsCode(this.ruleForm1.mobile).then(() => {
            this.invsec1 = 60;
            this.timer1 = setInterval(() => {
                if (this.invsec1 == 0) {
                    clearInterval(this.timer1);
                    return;
                }
                this.invsec1--;
            }, 1000);
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        })
    },
    // 修改密码
    submitForm1(formName) {
        this.$refs[formName].validate((valid) => {
            // if (valid) {
            //     modPwd(this.ruleForm1.oldpassword,this.ruleForm1.password).then(()=>{
            //         this.getPerson();
            //       this.editSafeType = this.safeFlag = false;
            //         this.$message.success("密码修改成功");
            //     }).catch(err=>{
            //         this.$message.error(err.message);
            //     });
            // } else {
            //     console.log('error submit!!');
            //     return false;
            // }
            if (valid) {
                this.ruleForm1.captcha_token = this.captchaToken;
                findPasswordReset(this.ruleForm1).then(()=>{
                    this.getPerson();
                    this.editSafeType = this.safeFlag = false;
                    this.$message.success("密码修改成功");
                }).catch(err=>{
                    this.$message.error(err.message);
                });
            } else {
                this.refreshCaptcha();// 刷新二维码
                console.log('error submit!!');
                return false;
            }
        });
    },
    next() {
      this.popVisible = false;
      this.editSafeType = this.safeFlag;
    },
  }
};
</script>
<style scoped>
  .block-safe >>> .el-form-item__label {
    padding: 0 20px 0 0;
    font-weight: bold;
  }
  .captcha-img {
      height: 38px;
      background-size: 100% 100% !important;
      width: 90px;
      cursor: pointer;
  }
  /* 密码强度 */
    .plan-progress>div {
		height: 10px;
        background: #eee;
        margin-bottom: 8px;
	}
	.plan-text span{
        font-size: 14px;
		line-height: 16px;
	}
</style>
<style scoped lang="less">
.btn-send {
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #808080;
  border-radius: 4px;
  background-color: #ccc;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.dialog-content {
  > div {
    width: 400px;
    margin-top: 20px;
    label {
      width: 100px;
      text-align: right;
      margin-right: 20px;
      color: #4d4d4d;
    }
    > div {
      color: #292929;
      width: 240px;
      .btn-send {
        margin-left: -92px;
      }
      .errtip {
        color: #f66f6a;
        font-size: 12px;
        height: 16px;
        margin-top: 10px;
      }
      .warm {
        color: #b3b3b3;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
}
.block {
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(209, 217, 226, 1);
  margin: 30px;
  h3 {
    font-size: 18px;
    color: #3b3b3b;
    height: 66px;
    padding: 0 30px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    span {
      font-size: 16px;
      cursor: pointer;
    }
  }
  .block-content {
    padding: 30px;
    .item {
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      &.required {
        > label:before {
          content: "*";
          display: inline;
          font-size: 16px;
          color: red;
          position: relative;
          top: 3px;
          margin-right: 2px;
        }
      }
      > div {
        a {
          margin-left: 10px;
        }
      }
      label {
        width: 60px;
        color: #4d4d4d;
        font-weight: bold;
        text-align: right;
        margin-right: 20px;
      }
      img {
        display: block;
        width: 120px;
        height: 120px;
      }
      .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        font-size: 0;
      }
      .avatar-uploader:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
      }
      .avatar {
        width: 120px;
        height: 120px;
        display: block;
      }
    }
    .item-des {
      > div {
        width: 400px;
      }
    }
  }
}
.btn-block {
  padding: 20px 30px;
  .el-button {
    margin-left: 32px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.block-safe {
  .block-content {
    > div {
      > div {
        width: 240px;
      }
    }
  }
}
</style>
